import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import SectionTitle from '../../../components/shared/section-title'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import Link from '../../../components/shared/link'
import { SectionWrap, ContactInfoWrap, SingleInfo } from './contact-info.stc'

const ContactInfo = ({ infoTextStyle, infoHeadingStyle, infoLinkStyle, lang }) => {

	const siteQueryData = useStaticQuery(graphql`
        query contactSiteDataQuery {
            site {
                siteMetadata {
					contact {
						company_address { ar en }
						company_email
						phone
						bankAccountNumber
						bankSwiftCode
						bankIbanCode
					}
                }
            }
			translationJson {
				contactInfo {
				  title { ar en }
				  address { ar en }
				  email { ar en }
				  phone { ar en }
				  bankAccountNumber { ar en }
				  bankSwiftCode { ar en }
				  bankIbanCode { ar en }
				}
			}
        }      
    `);
	const { company_address, company_email, phone, bankAccountNumber, bankSwiftCode, bankIbanCode } = siteQueryData.site.siteMetadata.contact
	const translations = siteQueryData.translationJson.contactInfo;
	const titles = {};
	Object.keys(translations).forEach(key => {
		titles[key] = translations[key][lang];
	});
	return (
		<SectionWrap>
			<div className="row justify-content-between align-items-center">
				<div className="col-4 offset-1">
					<ContactInfoWrap>
						<SectionTitle title={titles.title} />
						<div className="row">
							<div className="col-2">
								{company_address && (
									<SingleInfo className="wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1000ms">
										<Text {...infoTextStyle}>{titles.address}</Text>
										<Heading {...infoHeadingStyle}>{company_address[lang]}</Heading>
									</SingleInfo>
								)}
								{company_email && (
									<SingleInfo className="wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1000ms">
										<Text {...infoTextStyle}>{titles.email}</Text>
										<Heading {...infoHeadingStyle}>
											<Link {...infoLinkStyle} path={`mailto:${company_email}`}>{company_email}</Link>
										</Heading>
									</SingleInfo>
								)}

								<SingleInfo className="wow fadeInLeft" data-wow-delay="700ms" data-wow-duration="1000ms">
									<Text {...infoTextStyle}>{titles.phone}</Text>
									{phone && <Heading {...infoHeadingStyle}><Link {...infoLinkStyle} path="/">{phone}</Link></Heading>}
								</SingleInfo>
							</div>

							<div className="col-2">
								{bankAccountNumber && (
									<SingleInfo className="wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1000ms">
										<Text {...infoTextStyle}>{titles.bankAccountNumber}</Text>
										<Heading {...infoHeadingStyle}>{bankAccountNumber}</Heading>
									</SingleInfo>
								)}
								{bankSwiftCode && (
									<SingleInfo className="wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1000ms">
										<Text {...infoTextStyle}>{titles.bankSwiftCode}</Text>
										<Heading {...infoHeadingStyle}>{bankSwiftCode}</Heading>
									</SingleInfo>
								)}
								{bankIbanCode && (<SingleInfo className="wow fadeInLeft" data-wow-delay="700ms" data-wow-duration="1000ms">
									<Text {...infoTextStyle}>{titles.bankIbanCode}</Text>
									{phone && <Heading {...infoHeadingStyle}>{bankIbanCode}</Heading>}
								</SingleInfo>)}
							</div>
						</div>
					</ContactInfoWrap>
				</div>
			</div>
		</SectionWrap>
	)
}

ContactInfo.propTypes = {
	infoTextStyle: PropTypes.object,
	infoHeadingStyle: PropTypes.object
}

ContactInfo.defaultProps = {
	infoTextStyle: {
		fontSize: '12px',
		color: '#000',
		opacity: 0.8,
		fontweight: 400,
		texttransform: 'uppercase',
		mb: '5px'
	},
	infoHeadingStyle: {
		as: 'h6',
		fontSize: '12px',
		fontweight: 600,
		color: '#000',
		letterspacing: '0.5px',
		mb: 0,
		lineHeight: '22px'
	},
	infoLinkStyle: {
		color: '#000'
	}
}

export default ContactInfo
