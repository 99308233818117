import React from "react"
import Seo from '../components/seo';
import Layout from '../containers/layout/layout'
import ContactFormSection from '../containers/contact/contact-form'
import ContactInfoSection from '../containers/contact/contact-info'

const ContactPage = (props) => {
	const langKey = props.pageContext.langKey;
	return (
		<Layout lang={langKey} url="/contact" headerStyle="darkHeader">
			<Seo title="تواصل معنا" lang={langKey} />
			<ContactFormSection lang={langKey} />
			<ContactInfoSection lang={langKey} infoLinkStyle={{className: 'c-phone'}} />
		</Layout>
	)
}

export default ContactPage;
